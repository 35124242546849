import { createApp } from 'vue'
import App from './App.vue'
import {createAuth0} from "@auth0/auth0-vue";
import router from './router'
import PermissionsModule from '@/Javascript/PermissionsModule'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css';


const app = createApp(App)

//TODO: Change to env variables
const auth0 = createAuth0({
    domain: process.env.VUE_APP_DOMAIN,
    client_id: process.env.VUE_APP_CLIENT_ID,
    redirect_uri: window.location.origin,
    audience: process.env.VUE_APP_AUDIENCE,
})

// import callAPI from './Javascript/callAPI'
//
// const {apiGet, apiPut} = callAPI()
app
    .use(router)
    .use(auth0)
    .mount('#app');


router.beforeEach(async(to,from)=>{
    const {permission} = to.meta;
    
    if(permission){
        await auth0.getAccessTokenSilently().catch((error)=>{
            console.log(error)
            return auth0.loginWithRedirect()
        })
        if (Array.isArray(permission)) {
            let foundPermission = false
            // If it's an array, loop through each element
            for (let i = 0; i < permission.length; i++) {
                if(PermissionsModule.hasPermission(permission[i])) {
                    console.log("Permission Found:", permission[i])
                    foundPermission = true
                    break;
                }
            }
            if(!foundPermission){
                return from.path
            }
        } else {
            if(!PermissionsModule.hasPermission(permission)){
                return from.path
            }
        }

        // const userPermissions = VueJwtDecode.decode(token).permissions
        // if(!userPermissions.includes(permission)) {
        //     return from.path
        // }
    }
})